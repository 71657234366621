@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap");

.script-font {
  font-family: "Dancing Script", cursive;
}

.layout-sidebar-container {
  width: 15%;
}

.layout-outlate-container {
  width: 85%;
}

.outlet-container {
  margin-top: 64px;
}

@media screen and (max-width: 767px) {
  .layout-sidebar-container {
    width: 10%;
  }

  .layout-outlate-container {
    width: 90%;
  }

  .outlet-container {
    margin-top: 57px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .layout-sidebar-container {
    width: 30%;
  }

  .layout-outlate-container {
    width: 70%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .layout-sidebar-container {
    width: 25%;
  }

  .layout-outlate-container {
    width: 75%;
  }
}

@media screen and (min-width: 1200px) {
  .layout-sidebar-container {
    width: 20%;
  }

  .layout-outlate-container {
    width: 80%;
  }
}
