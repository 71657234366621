.client-details-container {
  margin-right: 50px;
  padding: 20px;
  border-radius: 10px;
  background-color: #f4f4f4;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 100%;
}

.client-details-container h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

.client-info {
  margin-bottom: 10px;
}

.label {
  font-weight: bold;
  color: #555;
  margin-right: 10px;
}

.balance-info {
  margin-top: 20px;
  color: #007bff;
  font-weight: bold;
  font-size: 20px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .client-details-container {
    margin-right: 0;
    padding: 10px;
  }

  .client-details-container h2 {
    font-size: 20px;
  }

  .balance-info {
    font-size: 18px;
  }
}
