.req-form-container {
    width: 100%;
    padding-top: 20px;
    height: 140vh;
    background-color: #f5f5fa;
    display: flex;
    justify-content: center;
    align-items: center;
}

.req-form-wrapper {
    height: 90%;
    width: 70%;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    display: flex;
    border-radius: 10px;
    overflow: hidden;
}

.req-form-left-content {
    flex: 1;
    background-color: #263773;
    color: #0da6ab;
    text-align: justify;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;

}
.req-form-sub-wrapper{
    padding-left: 20px;
}

.req-form-left-content p {
    font-size: 18px;
}

.req-form-right-content {
    flex: 1;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
}

.req-form-right-content form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
span{
    font-weight: 600;
}






@media screen and (max-width: 1200px) {
    .req-form-container {
        height: 90vh;    
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .req-form-wrapper {
        height: auto;
        padding-bottom: 40px;
    }

    .req-form-left-content {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .req-form-wrapper {
        width: 100%;
        height: auto;
    }

    .req-form-left-content {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .req-form-wrapper {
        width: 100%;
        height: auto;
    }

    .req-form-left-content {
        display: none;
    }
}