.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  /* opacity: 0; */
  /* visibility: hidden; */
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 100000000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 600px;
  text-align: center;
  transform: scale(0.8);
  transition: transform 0.3s ease-in-out;
}

.modal-overlay.show {
  opacity: 1;
  visibility: visible;
}

.modal-content.show {
  transform: scale(1);
}

.modal-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.modal-body input {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
}

.btn {
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn.cancel {
  background: red;
  color: white;
}

.btn.submit {
  background: green;
  color: white;
}

.open-modal-btn {
  padding: 10px 20px;
  background: blue;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
