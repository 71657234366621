/* Container styles */
.form{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.topup-wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f2f5;
}
.topup-container {
    width:50%;
    margin: 50px auto;
    padding: 30px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
}

/* Header styles */
.topup-header {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
    font-size: 24px;
}

/* Form group styles */
.topup-form-group {
    margin-bottom: 20px;
}

/* Label styles */
.topup-label {
    display: block;
    margin-bottom: 10px;
    color: #555;
    font-size: 16px;
}

/* Input styles */
.topup-input {
    width: 100%;
    padding: 15px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

/* Select styles */
.topup-select {
    width: 100%;
    padding: 15px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

/* Button styles */
.topup-button {
    width: 100%;
    padding: 15px;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    background-color: #28a745;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.topup-button:hover {
    background-color: #218838;
}

/* Error message styles */
.topup-error {
    color: red;
    text-align: center;
    margin-top: 15px;
    font-size: 16px;
}

@media only screen and (max-width: 540px) {
   .topup-container{
    width: 100%;
   }
}
