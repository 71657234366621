/* NotFoundPage.css */
.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
}

.not-found-content {
    text-align: center;
}

.not-found-heading {
    font-size: 6em;
    margin: 0;
    color: #dc3545;
}

.not-found-subheading {
    font-size: 2em;
    margin: 10px 0;
    color: #343a40;
}

.not-found-text {
    font-size: 1.2em;
    margin-bottom: 20px;
    color: #6c757d;
}

.back-to-home-btn {
    padding: 10px 20px;
    font-size: 1.2em;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.back-to-home-btn:hover {
    background-color: #0056b3;
}