.hero-section {
    height: 100vh;
    position: relative;
}

.hero-overlay {
    background: rgba(0, 0, 0, 0.9);
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    opacity: 0.8;
    z-index: 10;
}


.hero-content {
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
    color: white;
    position: absolute;
    z-index: 15;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.hero-content h1 {
    font-size: 80px;
    color: #f5f5fa;
}

.hero-content p {
    font-size: 30px;
    color: #f5f5fa;
}

.hero-content div {
    margin-top: 20px;
}

.hero-content div Button {
    margin-left: 10px;
    color: white;
}


@media (max-width: 768px) {
    .hero-content {
        padding-left: 20px;
        padding-right: 20px;
    }

    .hero-content h1 {
        font-size: 8vw;
    }

    .hero-content p {
        font-size: 4vw;
    }
}

@media (max-width: 576px) {
    .hero-content {
        padding-left: 20px;
        padding-right: 20px;
    }

    .button-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .hero-content h1 {
        font-size: 10vw;
    }

    .hero-content p {
        font-size: 5vw;
    }
}