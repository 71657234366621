/* General Table Wrapper Styling */
.table-wrapper {
  /* max-width: 1000px; */
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  position: relative;
}

/* Table Styling */
.custom-table {
  min-width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-family: "Arial", sans-serif;
}

.borderSpace1 {
  border-collapse: separate;
  border-spacing: 0px 12px;
}
.borderSpace2 {
  border-collapse: separate;
  border-spacing: 0px 8px;
}
.borderSpace3 {
  border-collapse: separate;
  border-spacing: 0px 4px;
}

.custom-table-column {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}

.custom-table th {
  background-color: #f1f1f1;
  min-width: 100px;
  color: #2a3577;
  padding: 12px 15px;
  font-size: 16px;
  /* text-transform: uppercase; */
  font-weight: 600;
  border-bottom: 3px solid #ddd;
  position: relative;
}

.custom-table td {
  padding: 12px 15px;
  font-size: 14px;
  /* color: #888; */
  border-bottom: 1px solid #ddd;
}

/* Row Styling */
.custom-table tr:nth-child(odd) {
  background-color: #fff;
}
.custom-table tr:nth-child(even) {
  background-color: #f3f1f1;
}

.custom-table tr:hover {
  background-color: #faf9f9;
}

/* Actions Column Styling */
.actions-column {
  display: flex;
  gap: 10px;
}

.action-btn {
  border: none;
  background: none;
  cursor: pointer;
  color: #1e3a8a;
  /* Dark blue */
  font-size: 18px;
  transition: color 0.3s ease;
}

.action-btn:hover {
  color: #1d4ed8;
  /* Lighter blue on hover */
}

/* Header icons */
.header-icons {
  display: flex;
  gap: 5px;
  margin-left: 5px;
}

.header-icons .MuiSvgIcon-root {
  cursor: pointer;
  color: rgb(152, 158, 224);
  transition: all 0.3s ease-in-out;
}

.header-icons .MuiSvgIcon-root:hover {
  color: #fff;
}

/* Popover Content Styling */
.popover-content {
  padding: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.highlight {
  background-color: yellow;
  font-weight: bold;
}

.rotate {
  rotate: 90deg;
}

.custom-input {
  padding: 8px;
  width: 200px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.custom-select {
  padding: 8px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.custom-input:focus,
.custom-select:focus {
  outline: none;
  border-color: #3f51b5;
  /* MUI default primary color */
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.page-button {
  padding: 10px;
  margin: 0 5px;
  border: 1px solid #ccc;
  background-color: white;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s;
}

.page-button:hover {
  background-color: #f0f0f0;
}

.page-button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.page-button.active {
  background-color: #333;
  color: white;
}

.custom-table th {
  position: relative;
}

.header-icons {
  display: flex;
  gap: 5px;
  position: absolute;
  right: 5px;
  top: 10px;
}

.highlight {
  background-color: yellow;
}

.submit-button {
  padding: 8px 10px;
  font-size: 12px;
  font-weight: bold;
  text-transform: none;
  margin-left: 4px;
}

/* Parent Container Styling */
.table-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  margin-top: 1rem;
  background: linear-gradient(
    to right,
    #3b82f6,
    #9333ea
  ); /* Blue to Purple Gradient */
  border-radius: 0 0 0.75rem 0.75rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Total Amount Styling */
.total-amount {
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
  margin-bottom: 1rem;
}

.highlighted-amount {
  color: #facc15;
}

/* Submit Button Styling */
.submit-btn {
  background-color: #facc15;
  color: #1f2937;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  font-size: 1.125rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Hover and Focus Effects */
.submit-btn:hover {
  background-color: #fbbf24;
  transform: scale(1.05);
}
