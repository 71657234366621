#invoice {
    background-color: #ffffff !important;
}

#invoice * {
    background-color: #ffffff !important;
}

.forceWhite {
    background-color: #ffffff !important;
}


/* Medium devices (tablets, 768px and up) */
@media only screen and (max-width: 1024px) {
    .tableContainer {
        margin-left: 10px;
    }

}

/* Small devices (phones, 600px and up) */
@media only screen and (max-width: 768px) {
    .tableContainer {
        margin-left: 12px;
    }

    .extraPadding {
        padding-right: 64px;
    }
}

/* Extra small devices (phones, less than 600px) */
@media only screen and (max-width: 600px) {
    .tableContainer {
        margin-left: 2px;
    }

    .extraPadding {
        padding-right: 56px;
    }
}