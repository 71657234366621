.distributionContainer {
    padding: 20px 0;
}

.css-19kzrtu {
    padding: 0 !important;
}

.css-11yukd5-MuiTabs-indicator {
    height: 6px !important;
}