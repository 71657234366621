.user-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    padding: 20px;
}

.user-header {
    text-align: center;
    color: #333;
    margin-bottom: 40px;
    font-size: 2.5em;
}

.user-detail {
    display: flex;
    justify-content: space-between;
    width: 80%;
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
    font-size: 1.2em;
}

.user-label {
    font-weight: bold;
    color: #555;
}

.user-value {
    color: #777;
}

.user-detail select.user-value {
    padding: 5px;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.change-password-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.change-password-form input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.change-password-button {
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 10px;
}

.change-password-button:hover {
    background-color: #45a049;
}

.user-negativeBalance {
    color: #777;
}

/* Media Queries for small and medium devices */
@media (max-width: 767px) {
    .user-header {
        font-size: 2em;
    }

    .user-detail {
        flex-direction: column;
        width: 100%;
        padding: 10px 0;
        font-size: 1em;
    }

    .user-label {
        margin-bottom: 5px;
    }

    .change-password-form {
        width: 100%;
    }
}