.footer {
    background-color: #263773;
    padding: 50px 0;
    color: #0da6ab;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
    bottom: 0;
    z-index: 10000000000;
}


.column {
    flex: 1 1 300px;
    margin: 0 20px;
    text-align: center;
}

.heading {
    font-size: 24px;
    margin-bottom: 20px;
    color: #0da6ab;
    border-bottom: 2px solid #0da6ab;
    padding-bottom: 10px;
}

.socialIcons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.iconLink {
    color: #0da6ab;
    font-size: 28px;
    margin: 0 10px;
    text-decoration: none;
    transition: color 0.3s ease;
}